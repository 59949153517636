.hamburger {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 25px;
}

.hamburger span {
    display: block;
    height: 3px;
    width: 100%;
    background: black;
    border-radius: 3px;
}

.hamburgerPic{
    background: -webkit-linear-gradient(-45deg,#0b1b2c, #f19c4a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.menu {
    /* Styles for your menu */
}
