.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #030a17;
    padding: 1rem;
}

.navbar-logo img {
    height: 40px; /* or the size you want */
}

.navbar-links {
    list-style: none;
    display: flex;
}

.nav-item {
    padding: 0 15px;
}

.nav-link {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}
