.App {
  text-align: center;

}
:root{
  background-color: #eee9dd;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.img {
  max-width:100%;
  height: auto;
}


.App-header {
  background-color: #eee9dd;
  min-height: 15vh;
  max-height: 1000px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-main {
  background-color: #eee9dd;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer{
  background-color: #eee9dd;
  min-height: 10vh;
}



.TitleText{
  background: -webkit-linear-gradient(-45deg,#0b1b2c, #859aab);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: Bahnschrift;
}

.App-link {
  color: #61dafb;
}


